import React, { FC, useMemo } from 'react';
import { useStyles } from './styles';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import cn from 'classnames';
import { billingService } from '@dar-dms/topbar';

import { icons } from './config';
import { QuickAccessProps, LinkProps } from './interfaces';
import { useWorkspace } from '../../services/tasks';
import { ReactComponent as LockIcon } from '../../assets/svg/lock.svg';

const QuickAccess: FC<QuickAccessProps> = ({ subscriptionTrigger }) => {
  const classes = useStyles()();
  const workspace = useWorkspace();

  const onLinkClick = (matomo) => {
    window._paq?.push([
      'trackEvent',
      'Home',
      matomo,
      JSON.stringify({
        holdingId: workspace?.holdingId,
        companyId: workspace?.id,
      }),
    ]);
  };

  return (
    <div className={classes.widget}>
      <div className={classes.container}>
        {icons.map((icon) => (
          <Link
            icon={icon}
            key={icon.id}
            onLinkClick={onLinkClick}
            subscriptionTrigger={subscriptionTrigger}
          />
        ))}
      </div>
    </div>
  );
};

const Link: FC<LinkProps> = ({ onLinkClick, icon, subscriptionTrigger }) => {
  const classes = useStyles()();
  const { t } = useTranslation();

  const isDisabled = useMemo(() => {
    if (!icon.module) {
      return false;
    }
    return !billingService.getPermission({
      module: icon.module,
      component: icon.component,
    });
  }, [icon.module, icon.component, subscriptionTrigger]);

  return (
    <RouterLink
      className={cn(classes.link, { [classes.linkDisabled]: isDisabled })}
      to={{ pathname: icon.link }}
      onClick={(e) =>
        isDisabled ? e.preventDefault() : onLinkClick(icon.matomo)
      }
    >
      <Tooltip
        title={
          isDisabled
            ? t('tariff.tariff_type_text', {
                ns: 'subscriptions',
                val: icon.availableOn,
              })
            : t(icon.name)
        }
      >
        <div className={classes.accessItem}>
          <div
            className={cn(classes.icon, { [classes.iconDisabled]: isDisabled })}
          >
            {icon.icon}
          </div>
          <div
            className={cn(classes.iconName, {
              [classes.iconNameDisabled]: isDisabled,
            })}
          >
            {t(icon.name)}
          </div>
          {isDisabled && <LockIcon className={classes.lockIcon} />}
        </div>
      </Tooltip>
    </RouterLink>
  );
};
export default QuickAccess;
