import React, { useContext } from 'react';
import { Task } from '../../../interfaces/tasks';
import { useStyles } from './TaskTable.styles';
import { TableContainer, Table, TableBody, Paper } from '@material-ui/core';
import TasksListHead from './TasksListHead';
import TasksCollapse from './TasksCollapse';
import { TaskManagerFiltersContext, useTasksStore } from '@dar-dms/utils';

export const hideStatuses = ['DONE', 'CANCEL', 'CANCELED'];

const TaskTable = () => {
  const classes = useStyles();
  const { order, orderBy, handleOrderChange, handleOrderByChange } = useContext(
    TaskManagerFiltersContext
  );
  const { tasks } = useTasksStore();
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Task
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    handleOrderChange(isAsc ? 'desc' : 'asc');
    handleOrderByChange(property);
  };

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table
        aria-label="collapsible table"
        stickyHeader
        style={{ tableLayout: 'fixed' }}
      >
        <TasksListHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          <TasksCollapse
            tasks={tasks.filter(
              (task) =>
                !hideStatuses.includes(
                  task.status?.replace(/\s/g, '').toUpperCase().trim()
                )
            )}
            key={0}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TaskTable;
