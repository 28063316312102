import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getProcessesTranslations } from "../../services/processes";


export function useGlossary() {
  const { i18n } = useTranslation();
  const [isGlossaryLoaded, setGlossaryLoaded] = useState(false);

  const glossariesKeys = useMemo(() => {
    if (isGlossaryLoaded) {
      const translationsEn = i18n.getResourceBundle('en', 'processesV2');
      const translationsRu = i18n.getResourceBundle('ru', 'processesV2');
      return Object.keys(translationsEn.glossary).reduce(
        (acc, key) => ({
          ...acc,
          [translationsEn.glossary[key]]: key,
          [translationsRu.glossary[key]]: key,
        }),
        {}
      )
    }
    return null;
  }, [isGlossaryLoaded, i18n]);

  useEffect(() => {
    /* 
      Cause hardcoded processes use ru and en translation glossaries, we have to get them here.
      NOTE: all irrelevant translations are deleted from localstorage on language change (trigger in weblayout module)
    */
    const languages = ['en', 'ru'];
    const shouldFetch = languages.some(lang => !i18n.hasResourceBundle(lang, 'processesV2') || !i18n.getResource(lang, 'processesV2', 'glossary'));
    Promise.all(languages.map(lang => shouldFetch ? getProcessesTranslations({ lang }) : Promise.resolve(null))).then((data) => {
      languages.forEach((lang, index) => {
        if (data[index] && i18n.hasResourceBundle(lang, 'processesV2')) {
          i18n.addResources(lang, 'processesV2', data[index]);
        } else {
          i18n.addResourceBundle(lang, 'processesV2', data[index], true);
        }
      });
      setGlossaryLoaded(true);
    }).catch((e) => {
      console.log('Error fetching processes glossary', e)
    });
  }, [i18n])

  return glossariesKeys;
}