import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  noDataCenter: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: 'none',
    justifyContent: 'center',
  },
  noElementsText: {
    fontWeight: 'normal',
    textAlign: 'center',
    color: theme.palette.grey[600],
    fontSize: '14px',
    lineHeight: '18px',
  },
}));
