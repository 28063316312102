import { AxiosError } from 'axios';

import { getCompanyId } from "./tasks";

export const commonExceptionHandler = (error: AxiosError) => {
  return Promise.reject(error);
};

export const addTrackingHeaders = (config) => {
  const companyId = getCompanyId();
  const { id: userId } = JSON.parse(localStorage.getItem('profile'));

  config.headers = {
    ...config.headers,
    'dar-dms-org-id': companyId,
    'dar-dms-user-id': userId,
  };

  return config;
};
