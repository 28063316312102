import React, { Suspense, useEffect, useState } from 'react';
import { theme } from '@dartech/dms-ui';
import { BrowserRouter as Router } from 'react-router-dom';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/className';
import MainPage from './pages/MainPage';
import { authService } from '@dar-dms/topbar';
import { GlobalStoreContext } from './contexts/globalStore';
import {
  ErrorBoundary,
  MeetingFilterProvider,
  globalStoreData,
} from '@dar-dms/utils';
import { Provider } from 'react-redux';
import { store as rtkQueryStore } from './store';
import './i18';

const generateClassName = createGenerateClassName({
  productionPrefix: 'dar-dms-home',
  seed: 'home',
});

const Root = () => {
  const [authLoaded, setAuthLoaded] = useState(false);

  useEffect(() => {
    if (authService) {
      const sub = authService.loggedIn.subscribe((auth) => {
        if (auth) {
          setAuthLoaded(true);
        }
      });
      return () => {
        sub.unsubscribe();
      };
    }
  }, []);

  return (
    <ErrorBoundary moduleName="dar-dms-home">
      <StyledEngineProvider injectFirst>
        <StylesProvider generateClassName={generateClassName}>
          <Suspense fallback={<div>loading...</div>}>
            <MeetingFilterProvider>
              <Provider store={rtkQueryStore}>
                <Provider
                  store={globalStoreData.store}
                  context={GlobalStoreContext}
                >
                    <Router>
                      <ThemeProvider theme={theme}>
                        {authLoaded && <MainPage />}
                      </ThemeProvider>
                    </Router>
                </Provider>
              </Provider>
            </MeetingFilterProvider>
          </Suspense>
        </StylesProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
  );
};

export default Root;
