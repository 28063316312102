export interface IWidget {
  id: string;
  name: string;
  icon: JSX.Element;
  link: string;
  matomo: MATOMO_KEYS;
  // below subscription info
  module?: string;
  component?: string;
  availableOn?: string;
}

export interface QuickAccessProps {
  subscriptionTrigger: boolean;
}
export interface LinkProps {
  icon: IWidget;
  onLinkClick: (matomo: MATOMO_KEYS) => void;
  subscriptionTrigger: boolean;
}

export enum MATOMO_KEYS {
  MOMS = 'Ckick_on_Meeting_notes_web',
  TASKS = 'Ckick_on_Tasks_web',
  TIMESHEET = 'Ckick_on_Timesheet_web',
  PROJECTS = 'Ckick_on_Project_web',
  APPROVALS = 'Ckick_on_Approvals_web',
  REQUESTS = 'Ckick_on_Requests_web',
  ORG_STRUCTURE = 'Ckick_on_Orgstructure_web',
}
