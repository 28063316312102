import React from 'react';
import { Card, Box } from '@material-ui/core';
import cn from 'classnames';

import useStyles from './useStyles';

const TicketSkeleton = () => {
  const classes = useStyles();

  return (
    <Card className={classes.cardPlaceholder} elevation={0}>
      <Box className={classes.cardWrapper}>
        <div className={classes.cardSkeletonContent}>
          <div className={classes.taskInfoWrapper}>
            <div className={classes.processInfo}>
              <div className={classes.processIconWrapper}>
                <div className={classes.processIconPlaceholder} />
              </div>
              <div className={classes.processNamePlaceholder} />
            </div>
            <Box
              className={cn(classes.summaryPlaceholder, classes.animated)}
            ></Box>
          </div>
          <div className={classes.taskStatusWrapper}>
            <div className={classes.taskStatusPlaceholder} />
          </div>
        </div>
      </Box>
    </Card>
  );
};

export default TicketSkeleton;
