import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './UpgradeProcesses.styles';
import { Button, Typography } from '@mui/material';
import parse from 'html-react-parser';
import { useWorkspaceToken } from '@dar-dms/utils';
import { ReactComponent as TrendingUp } from '../../assets/svg/trending_up.svg';

import { ReactComponent as UpgradeProcessesKz } from '../../assets/svg/processes_upgrade_kz.svg';
import { ReactComponent as UpgradeProcessesRu } from '../../assets/svg/processes_upgrade_ru.svg';
import { ReactComponent as UpgradeProcessesDe } from '../../assets/svg/processes_upgrade_de.svg';
import { ReactComponent as UpgradeProcessesId } from '../../assets/svg/processes_upgrade_id.svg';
import { ReactComponent as UpgradeProcessesEn } from '../../assets/svg/processes_upgrade_en.svg';

const localizedIcons = {
  kz: UpgradeProcessesKz,
  ru: UpgradeProcessesRu,
  de: UpgradeProcessesDe,
  id: UpgradeProcessesId,
  en: UpgradeProcessesEn,
};

const UpgradeProcessesIcon = ({ Icon }) => {
  if (!Icon) return null;
  return <Icon />;
}

const UpgradeProcesses = () => {
  const { t, i18n } = useTranslation('subscriptions');
  const classes = useStyles();

  const { token } = useWorkspaceToken();
  const icon = localizedIcons[i18n.language];

  return (
    <div className={classes.widget}>

      <div className={classes.container}>
        <h3 className={classes.title}>
          {t('tariff.request_details')}
        </h3>
        <div className={classes.textWrapper}>
          <h3 className={classes.subTitle}>
            {parse(t('tariff.request_title', {
              startHightlight: `<span>`,
              endHighlight: `</span>`,
            }))}
          </h3>
          <p className={classes.text}>{t('tariff.request_body_text')}</p>
          {(['ADMIN', 'OWNER'].includes(token?.role) && (
              <Button
                variant="contained"
                color="primary"
                className={classes.upgradeButton}
                onClick={() => window.location.replace('/admin/plans')}
                startIcon={<TrendingUp />}
              >
                {t('tariff.request_button')}
              </Button>
            )) || (
              <Typography className={classes.contactText}>
                {t('limitations_team.contact_admin')}
              </Typography>
            )}
        </div>

      </div>
        <div className={classes.iconWrapper}>
          <UpgradeProcessesIcon Icon={icon} />
        </div>
    </div>
  );
};

export default UpgradeProcesses;