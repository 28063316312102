import { List, ListItem } from '@mui/material';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TaskRequestType } from '../../interfaces/processes';
import { useStyles } from './Processes.styles';
import { Ticket } from './Ticket/Ticket';
import TicketSkeleton from './Ticket/TicketSkeleton';
import { ReactComponent as ListPokerface } from '../../assets/svg/list-pokerface.svg';
import { useTranslation } from 'react-i18next';
import Empty from '../Empty';
type Props = {
  type: string;
  processes: TaskRequestType[];
  currentTab: number;
  nextFunction: () => void;
  hasMore: boolean;
};

const ProcessesList: React.FC<Props> = ({
  type,
  processes,
  currentTab,
  nextFunction,
  hasMore,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.tabPanel} id={`active${type}Tab`}>
      {!processes || (!hasMore && processes.length === 0) ? (
        <Empty
          title={
            type === 'requests'
              ? t('overview.Overview_list_of_requests')
              : t('overview.Overview_list_of_approvals')
          }
        />
      ) : (
        <List>
          <InfiniteScroll
            dataLength={processes.length}
            next={nextFunction}
            hasMore={hasMore}
            loader={hasMore && <TicketSkeleton />}
            scrollableTarget={`active${type}Tab`}
          >
            {processes.map((approval) => (
              <ListItem key={approval.businessTask.taskId} disableGutters dense>
                <Ticket request={approval} type={type === 'requests' ? 'myRequest' : 'approval'} active={false} currentTab={currentTab}/>
              </ListItem>
            ))}
          </InfiniteScroll>
        </List>
      )}
    </div>
  );
};
export default ProcessesList;
