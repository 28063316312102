import moment from 'moment';
import { baseApi } from '../../services/rtk-query/baseApi';
import { Meeting } from '@dar/api-interfaces';
import { format } from 'date-fns';
import { dateToUTCDate } from '../../utils/general';

export const fullFormat = 'YYYY-MM-DDTHH:mm:ss';
export const meetingFormat = 'yyyy-MM-dd HH:mm:ss.SSS';

interface Props {
  startDate: Date;
  endDate: Date;
}

export const meetingsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getMeetingList: builder.query<Meeting[], Props>({
      providesTags: ['Meetings'],
      query: ({ startDate, endDate }) => {
        const params = {
          start: moment(startDate).startOf('day').format(fullFormat),
          end: moment(endDate).format(fullFormat),
        };

        return {
          url: `/new-meeting`,
          params,
          method: 'GET',
        };
      },
      transformResponse: (response: Meeting[], meta) => {
        return response.map((meeting) => ({
          ...meeting,
          startDate: format(
            dateToUTCDate(new Date(`${meeting.date}T${meeting.startTime}`)),
            meetingFormat
          ),
          endDate: format(
            dateToUTCDate(new Date(`${meeting.date}T${meeting.endTime}`)),
            meetingFormat
          ),
          startTime: format(
            dateToUTCDate(new Date(`${meeting.date}T${meeting.startTime}`)),
            meetingFormat
          ),
          endTime: format(
            dateToUTCDate(new Date(`${meeting.date}T${meeting.endTime}`)),
            meetingFormat
          ),
          date: dateToUTCDate(new Date(meeting.date)),
        }));
      },
    }),
  }),
});

export const {
  useGetMeetingListQuery,
  useLazyGetMeetingListQuery,
} = meetingsApi;
