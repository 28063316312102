import { toast } from '@dartech/dms-ui';
import {
  Middleware,
  MiddlewareAPI,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { translateFromLocalizationKey } from '../../utils/parseError';

const errorStatuses = [404, 403];

const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (
  action
) => {
  if (
    isRejectedWithValue(action) &&
    !errorStatuses.includes(action.payload.status)
  ) {
    if (action.payload.status) {
      toast.error(
        `${action.payload.status}: ${
          action.payload.data?.localizationKey
            ? translateFromLocalizationKey(action.payload.data?.localizationKey)
            : action.payload.data?.error
        }`
      );
    } else {
      toast.error(
        action.error.localizationKey
          ? translateFromLocalizationKey(action.error.localizationKey)
          : action.error.message
      );
    }
  }
  return next(action);
};

export const middlewareHandler = (getDefaultMiddleware) => {
  const middlewareList = [rtkQueryErrorLogger, ...getDefaultMiddleware];
  // if (process.env.NX_IS_LOCAL) {
  //   middlewareList.push(logger);
  // }
  return middlewareList;
};
