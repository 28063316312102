import { Action, configureStore } from '@reduxjs/toolkit';
import { AppState, rootReducer } from './reducers';
import { middlewareHandler } from './middlewares';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import { baseApi } from '../services/rtk-query/baseApi';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    middlewareHandler(
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(promiseMiddleware, thunk, baseApi.middleware)
    ),
});

export type AppThunk<T> = ThunkAction<T, AppState, unknown, Action<string>>;
export type AppDispatch = ThunkDispatch<AppState, undefined, Action<string>>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export default store;
