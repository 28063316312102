import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  widget: {
    width: '100%',
    height: '100%',
    background: '#FFFFFF',
    border: '1px solid rgba(38, 40, 66, 0.08)',
    borderRadius: '10px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.04)',
    padding: '20px',
  },
  title: {
    margin: 0,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  tabsWrapper: {
    flex: 1,
  },
  tabs: {
    border: 'none',
    position: 'relative',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-6px',
      left: '0',
      width: '100%',
      height: '46px',
      borderBottom: '1px solid #E0E3EA',
    },
    '& .Mui-selected:after': {
      background: '#007F9B',
    },
  },
  tabPanel: {
    marginLeft: '20px',
    paddingRight: '10px',
  },
  panel: {
    maxHeight: '79%',
    overflow: 'auto',
  },
  noDataCenter: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: 'none',
    justifyContent: 'center',
  },
  noElementsText: {
    fontWeight: 'normal',
    textAlign: 'center',
    color: theme.palette.grey[600],
    fontSize: '14px',
    lineHeight: '18px',
  },
}));
