import { theme } from '@dartech/dms-ui';
import { createTheme } from '@material-ui/core';

theme.props = {
  MuiButton: {
    disableElevation: true,
    disableFocusRipple: true,
    disableTouchRipple: true,
    variant: 'contained',
  },
  MuiIconButton: {
    disableRipple: true,
    disableFocusRipple: true,
    disableTouchRipple: true,
  },
  MuiInput: {
    disableUnderline: true,
  },
};

export const customTheme = createTheme({
  // spacing: [4, 4, 4, 4, 4, 4],
  palette: {
    info: {
      light: '#62F3FB',
      main: '#2FDEF9',
      dark: '#22AFD6',
      100: '#D5FEF7',
      200: '#ABFEF5',
      300: '#81FDFA',
      400: '#62F3FB',
      500: '#2FDEF9',
      600: '#22AFD6',
      700: '#1785B3',
      800: '#0E6090',
      900: '#094577',
    },
    primary: {
      light: '#F1855C',
      main: '#3781CB',
      dark: '#E06537',
      100: '#3C95D6',
      200: '#1B74B4',
      300: '#0B69AC',
      400: '#F1855C',
      500: '#EA7042',
      600: '#E06537',
      700: '#007F9B',
    },
    warning: {
      light: '#FFBD42',
      main: '#FFA530',
      dark: '#FF9021',
      100: '#FFF8EB',
      200: '#FFE8BF',
      300: '#FFD68A',
      400: '#FFBD42',
      500: '#FFA530',
      600: '#FF9021',
      700: '#FF7B0F',
      800: '#E8650E',
      900: '#7A2E15',
    },
    error: {
      light: '#ED4D36',
      main: '#E02E14',
      dark: '#BB2611',
      100: '#FDE6E3',
      200: '#F9C1B9',
      300: '#F38B7C',
      400: '#ED4D36',
      500: '#E02E14',
      600: '#BB2611',
      700: '#9F200E',
      800: '#831A0C',
      900: '#6C150A',
    },
    success: {
      light: '#57DB95',
      main: '#2DB77B',
      dark: '#00875A',
      100: '#E9FEEE',
      200: '#C7F9D4',
      300: '#91F3B5',
      400: '#57DB95',
      500: '#2DB77B',
      600: '#00875A',
      700: '#007458',
      800: '#00624A',
      900: '#004938',
    },
    secondary: {
      light: '#ACB6BF',
      main: '#8A96A1',
      dark: '#465564',
    },
    grey: {
      50: '#FFFFFF',
      100: '#F5F8FA',
      200: '#E6ECF1',
      300: '#CED7DF',
      400: '#ACB6BF',
      500: '#8A96A1',
      600: '#465564',
      700: '#1D2B36',
      800: '#101F2B',
      900: '#050E14',
    },
  },
  overrides: {
    MuiInput: {
      /* Styles applied to the root element. */
      root: {
        backgroundColor: theme.palette.grey[50],
        border: '1px solid ' + theme.palette.grey[300],
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        fontSize: '16px',
        lineHeight: '18px',
        color: theme.palette.text.primary,
        height: 40,
        '&:hover': {
          border: '1px solid ' + theme.palette.primary[200],
        },
        '&$focused': {
          border: '2px solid ' + theme.palette.primary[200],
          padding: theme.spacing(2) - 1,
          paddingTop: theme.spacing(3) - 1,
        },
        '&$disabled': {
          backgroundColor: theme.palette.grey[200],
          '& input::placeholder': {
            color: theme.palette.text.disabled,
          },
        },
      },
      error: {
        border: '1px solid ' + theme.palette.error[600],
      },
      /* Styles applied to the children if `size="small"`. */
      marginDense: {
        fontSize: theme.typography.fontSize,
        lineHeight: '12px',
        paddingTop: theme.spacing(3),
        height: 32,
      },
    },
    MuiSelect: {
      /* Styles applied to the root element. */
      icon: {
        fill: theme.palette.grey[700],
        '&$disabled': {
          fill: '#A5AAC0',
        },
      },
      iconOpen: {
        transform: 'none',
      },
    },
  },
});
