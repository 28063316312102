import React, { ReactElement } from 'react';

type Props = {
  children?: ReactElement;
  value: number;
  index: number;
  className?: string;
};

const TabPanel: React.FunctionComponent<Props> = ({ children, value, index, className = '' }) => (
  <div role="tabpanel" hidden={value !== index} className={className}>
    {children}
  </div>
);

export default TabPanel;
