import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { theme as darTheme } from '@dartech/dms-ui';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    svgGradient: {
      height: 0,
      width: 0,
    },
    circleWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      color: '#EBEEF0'
    },
    circleInProgress: {
      strokeLinecap: 'round',
      stroke: '#007994',
    },
    circleCompleted: {
      strokeLinecap: 'round',
      color: '#2DB77B',
    },
    completedIcon: {
      width: 26,
      height: 26
    },
    stepLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: `${darTheme.palette.grey[500]}`,
      marginBottom: 3,

      '& img': {
        marginBottom: -2,
        width: 26,
        height: 26,
      }
    },
    stoppedIndicator: {
      width: 40,
      height: 40,
    },
    assigneeList: {
      margin: 0,
      paddingLeft: theme.spacing(5),
    },
    assigneeListItem: {
    },
    assigneeListItemIconBig: {
      height: 10
    },
    assigneeListItemIconSmall: {
      height: 2
    },
    tooltip: {
      padding: '8px 12px',
      borderRadius: 8,
    },
    statusWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    statusIcon: {
      width: 16,
      height: 16,
      marginRight: 4,
    },
    stepInfoWrapper: {
      marginTop: 8,
      maxHeight: 200,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    stepInfo: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      color: '#F7F8FC',
      '& p': {
        margin: 0,
      },
      '& ul': {
        margin: 0,
        padding: 0,
        paddingLeft: 24,
      }
    },
    stepInfoLabel: {
      fontWeight: 600,
    },
    notFinalCountInfoMessageWrapper: {
      width: '100%',
      marginTop: 8,
      borderRadius: 4,
      padding: 4,
      background: 'rgba(255, 255, 255, 0.08)'
    }
  })
);

export default useStyles;
