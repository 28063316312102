import { makeStyles } from '@material-ui/core';
import { theme as darTheme } from '@dartech/dms-ui';
import {CARD_COLLAPSE_HEIGHT_POINT} from "./useTicket";

const useStyles = makeStyles(() => ({
  approve: {
    paddingLeft: '0 !important',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding:'4px 0 4px 0',
  },
  wrapperUnread: {
    "&::before": {
      content: '""',
      width: 8,
      height: 8,
      position: 'absolute',
      top: '50%',
      marginTop: -4,
      left: 4,
      borderRadius: '50%',
      backgroundColor: '#E94E3A',
    }
  },
  card: {
    minHeight: 96,
    height: 'fit-content',
    maxHeight: 400,
    width: '100%',
    display: 'flex',
    border: `1px solid ${darTheme.palette.grey[300]}`,
    borderRadius: 15,
    transition: '0.2s ease border-color, 0.2s background-color, 0.5s max-height',
    '&:hover': {
      backgroundColor: darTheme.palette.primary[200],
      borderColor: darTheme.palette.primary[200],
      '& > $cardWrapper': {
        backgroundColor: '#FFFFFF',
        borderColor: darTheme.palette.primary[200]
      }
    },
  },
  cardActive: {
    backgroundColor: darTheme.palette.primary[200],
    borderColor: darTheme.palette.primary[200],
    '& > $cardWrapper': {
      backgroundColor: '#FFFFFF',
      borderColor: darTheme.palette.primary[200]
    }
  },
  cardCollapsed: {
    height: CARD_COLLAPSE_HEIGHT_POINT,
    maxHeight: CARD_COLLAPSE_HEIGHT_POINT,
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'block',
      width: '100%',
      height: 40,
      position: 'absolute',
      bottom: 0,
      left: 0,
      background: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
    }
  },
  cardUrgent: {
    backgroundColor: '#FFF1F0'
  },
  showMoreButton: {
    position: 'absolute',
    bottom: 16,
    right: 8,
  },
  cardWrapper: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: 'fit-content',
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    transition: '0.2s background-color',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    cursor: 'pointer',
    padding: '10px 12px',
  },
  cardSkeletonContent: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    cursor: 'pointer',
    padding: '10px 12px',
  },
  taskInfoWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  processInfo: {
    height: 28,
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  processIconWrapper: {
    width: 28,
    height: 28,
    marginRight: 8,
  },
  processIcon: {
    width: '100%',
    height: '100%',
  },
  processName: {
    fontSize: 14,
    fontWeight: 400,
    color: darTheme.palette.grey[500],
  },
  cardReworkIndicator: {
    position: 'absolute',
    right: 32,
    top: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#262842',
    '& img': {
      height: 16,
      width: 16,
      marginRight: 4,
    }
  },
  summary: {
    marginLeft: 36,
    marginRight: 66,
    fontSize: 14,
    fontWeight: 400,
    color: darTheme.palette.grey[700],
  },
  urgentWrapper: {
    marginLeft: 36,
    marginRight: 12,
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row',
  },
  urgentIndicator: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: `${darTheme.palette.error[600]}`,
    fontSize: 12,
    fontWeight: 500,
    background: `${darTheme.palette.error[100]}`,
    borderRadius: 4,
    height: 20,
    width: 66,
  },
  urgentIcon: {
    width: 10,
    height: 'auto',
    marginRight: 4,
  },
  urgentReason: {
    marginLeft: 8,
    fontSize: 12,
    fontWeight: 400,
    color: `${darTheme.palette.grey[500]}`,
  },
  taskStatusWrapper: {
    width: 40,
    marginLeft: 12,
  },
  taskStatus: {
    width: 40,
    height: 40,
  },
  statusIcon: {
    width: 8,
    height: 8,
    display: "inline-block",
    borderRadius: "50%",
    marginRight: "4px"
  },
  priorityIcon: {
    width: 20,
    height: 20,
    marginLeft: -4
  },
  cardBadge: {
    position: 'absolute',
    top: 16,
    right: 16,

    backgroundColor: '#FDE6E3',
    borderRadius: 4,

    padding: '2px 4px 2px 8px',
  },
  cardBadgeTitle: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.6,
    color: darTheme.palette.error[600],
  },
  cardContentHeaderIcon: {
    height: 20,
    width: 20
  },
  cardContentHeaderTitle: {
    color: '#6D6E85',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',

    marginLeft: 6,

    width: 'fit-content',
    overflow: "hidden",
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  summaryHtmlWrapper: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: darTheme.palette.grey[700]
  },
  cardTooltipPopper: {
    pointerEvents: 'all',
  },
  cardTooltip: {
    color: '#F7F8FC',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    maxWidth: 240,
    width: 'fit-content',
    padding: '8px 12px',
    borderRadius: 10,
  },
  cardTooltipLongText: {
    maxWidth: 320,
    width: 'fit-content'
  },
  overdueIcon: {
    height: 10,
    width: 'fit-content',
    marginBottom: 2
  },
  overdueText: {
    fontSize: 16,
    color: '#D6331F'
  },
  cardStatus: {
    width: 48,
    marginLeft: 16,
    marginRight: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  cardContentUrgentTitle: {
    display: 'block',
    overflow: 'hidden',
    width: '100%',

    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  cardContentFooterTitle: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: '#6D6E85'
  },
  cardContentFooterTitleOverdue: {
    color: '#D6331F',
    fontWeight: 500,
  },
  cardContentFooterButton: {
    display: 'flex',
    alignItems: 'center',

    marginLeft: 8
  },
  cardContentFooterIcon: {
    height: 20,
    width: 20,

    marginRight: 4,
  },
  cardContentDueDate: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 8,
    paddingLeft: 8,
    position: 'relative',
    '& :before': {
      content: '""',
      position: 'absolute',
      width: 1,
      height: 17,
      left: 0,
      top: 1.5,
      background: 'rgba(38, 40, 66, 0.12)'
    }
  },
  cardPlaceholder: {
    minHeight: 96,
    width: '100%',
    display: 'flex',
    border: `1px solid ${darTheme.palette.grey[300]}`,
    borderRadius: 15,
    marginTop: 4,
  },
  processIconPlaceholder: {
    backgroundColor: "#E3EBF2",
    borderRadius: "4px",
    width: 28,
    height: 28,
  },
  processNamePlaceholder: {
    backgroundColor: "#E3EBF2",
    borderRadius: "4px",
    width: 88,
    height: 18,
  },
  summaryPlaceholder: {
    marginLeft: 36,
    height: 18,
    width: 530,
    backgroundColor: "#E3EBF2",
    borderRadius: "4px",
  },
  taskStatusPlaceholder: {
    width: 40,
    height: 40,
    backgroundColor: "#E3EBF2",
    borderRadius: "4px",
  },
  "@keyframes moving-gradient": {
    "0%": { backgroundPosition: "-265px 0" },
    "100%": { backgroundPosition: "265px 0" },
  },
  animated: {
    background: "linear-gradient(to right, #E3EBF2 20%, #D4E2EC 50%, #E3EBF2 80%)",
    animationName: `$moving-gradient`,
    animationDuration: "1s",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
    animationFillMode: "forwards",
  },
  summaryFieldsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 4,
  },
  summaryField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  summaryFieldHintWrapper: {
    maxWidth: 400,
    width: 'max-content',
  },
  summaryFieldHint: {
    maxWidth: 400,
    width: 'max-content',
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    color: '#6D6E85',
    lineHeight: '18px',
  },
  summaryFieldValue: {
    maxWidth: 700,
    marginLeft: 4,
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '18px',
    color: '#262842',
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  summaryFieldValueCount: {
    fontWeight: 500,
  },
  processDescription: {
    maxWidth: 700,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    fontStyle: 'normal',
    color: '#262842',
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  summaryFieldEmptyValue: {
    color: '#8B8C9E'
  },
  ticketChip: {
    position: 'absolute',
    top: 16,
    right: 64,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
    padding: '4px 6px',
    borderRadius: 6,
    background: '#F0F0F7',
    '& img': {
      width: 16,
      height: 16,
    },
    '& span': {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
      color: '#262842',
    },
  },
}));

export default useStyles;
