import { environment } from '../environments/environment';
import { ProcessesPaginatedList } from '../interfaces/processes';
import { apiClient } from './clients';

export const getApprovals = async ({ pageSize, pageNumber, companyId }) => {
  return apiClient
    .get<ProcessesPaginatedList>(
      `${environment.overviewApiRoot}/approvals?page=${pageNumber}&size=${pageSize}&sort=taskStartDate,DESC`,
      {
        headers: {
          companyId,
        },
      }
    )
    .then((res) => res.data);
};

export const getRequests = async ({ pageSize, pageNumber, companyId }) => {
  return apiClient
    .get(
      `${environment.overviewApiRoot}/requests?&page=${pageNumber}&size=${pageSize}&sort=taskStartDate,DESC`,
      {
        headers: {
          companyId,
        },
      }
    )
    .then((res) => res.data);
};

export const getHoldingSpecificProcessesTranslations = async ({ lang }) => {
  const workspaceData = JSON.parse(localStorage.getItem('workspace'));
  const holdingId = workspaceData?.holdingId || '';
  return apiClient
    .get(
      `${environment.dmsBaseApi}/dictionary-api/localization/default/all`,
      {
        params: {
          lang,
          module: 'processesV2',
          holdingId
        },
      }
    )
    .then((res) => res.data);
};

export const getProcessesTranslations = async ({ lang }) => {
  return apiClient
    .get(
      `${environment.dmsBaseApi}/dictionary-api/localization/all`,
      {
        params: {
          lang,
          module: 'processesV2',
        },
      }
    )
    .then((res) => res.data);
};
