import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  widget: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    background: '#F7F8FC',
    border: '1px solid rgba(38, 40, 66, 0.08)',
    borderRadius: '10px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.04)',
  },
  title: {
    margin: 0,
    width: '100%',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'left',
    color: '#000000',
  },
  subTitle: {
    display: '-webkit-box',
    marginBottom: '12px',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'left',
    '-webkitLineClamp': 2,
    '-webkitBoxOrient': 'vertical',

    '& span': { color: '#039BE6' },

    [theme.breakpoints.up('xl')]: {
      fontSize: 32,
      lineHeight: '36px',
    },
  },
  text: {
    display: '-webkit-box',
    marginBottom: '20px',
    overflow: 'hidden',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'left',
    color: '#6D6E85',
    '-webkitLineClamp': 4,
    '-webkitBoxOrient': 'vertical',

    [theme.breakpoints.up('xl')]: {
      fontSize: 16,
    },
  },
  container: {
    padding: '20px 0 0 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    flexGrow: 2,
    overflow: 'hidden',
  },
  textWrapper: {
    height: '100%',
    padding: '14px 0 14px 14px',
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  upgradeButton: {
    borderRadius: '10px',
    height: 40,
    width: '100%',
    maxWidth: '256px',
    marginBottom: '20px',
    // TODO: move to theme if other buttons are needed
    backgroundColor: '#039BE6',
    border: 'none',
    borderColor: `transparent`,
    '&:hover': {
      backgroundColor: '#0692D9',
      borderColor: `#0692D9`,
    },
    '&:active': {
      backgroundColor: '#088DD2',
      borderColor: `#088DD2`,
    },
  },
  contactText: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 600,
  },
  iconWrapper: {
    overflow: 'hidden',
    minHeight: 248,
    flexGrow: 1,
    paddingTop: '20px',
    minWidth: '292px',

    '& svg': {
      width: '100%',
      height: '100%',
    }
  }
}));
