import {
  BaseQueryApi,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { environment } from '../../environments/environment';
import { User } from '../../interfaces/tasks';

const baseQuery = fetchBaseQuery({
  baseUrl: environment.commsApiRoot,
  prepareHeaders: async (headers: Headers, { getState }) => {
    const token = JSON.parse(localStorage.getItem('dms-auth')).id_token;
    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }
    const workspaceToken = localStorage.getItem('workspace_token');
    if (workspaceToken) {
      headers.append('Workspace-Authorization', workspaceToken);
    }
    const user = JSON.parse(window.localStorage.getItem('profile')) as User;
    if (user) {
      headers.append('dar-dms-user-id', user.id);
    }
    const workspace = JSON.parse(localStorage.getItem('workspace'));
    if (workspace) {
      headers.append('dar-dms-org-id', workspace.id);
    }
    const lng = localStorage.getItem('i18nextLng');
    if (lng) {
      headers.set('Accept-Language', lng);
    }
    return headers;
  },
});

// eslint-disable-next-line
const baseQueryWithRefresh = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: any
) => {
  let result = await baseQuery(args, api, extraOptions);
  // const refreshToken = JSON.parse(localStorage.getItem('dms-auth')).refresh_token;
  if (result.error && result.error.status === 401 /* && refreshToken */) {
    // const response = await fetch(``, {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //   },
    // });
    // const data: { data: { token: string; refreshtoken: string } } = await response.json();
    result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
      // logout
    }
  }
  return result;
};

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: baseQueryWithRefresh,
  tagTypes: ['Meetings'],
  endpoints: (builder) => ({}),
});
