import { ReactComponent as MoMIcon } from '../../assets/svg/mom.svg';
import { ReactComponent as TaskIcon } from '../../assets/svg/tasks.svg';
import { ReactComponent as ApprovalIcon } from '../../assets/svg/approvals.svg';
import { ReactComponent as RequestIcon } from '../../assets/svg/requests.svg';
import { ReactComponent as OrgstructureIcon } from '../../assets/svg/orgstructure.svg';
import { ReactComponent as TimesheetIcon } from '../../assets/svg/timesheet.svg';
import { ReactComponent as ProjectIcon } from '../../assets/svg/project.svg';
import { IWidget, MATOMO_KEYS } from './interfaces';
import React from 'react';

export const icons: IWidget[] = [
  {
    id: 'widget_mom',
    name: 'overview.Overview_quick_access_MOM',
    icon: <MoMIcon style={{ width: '24px' }} />,
    link: '/productivity/moms?shouldCollapse=true',
    matomo: MATOMO_KEYS.MOMS,
    module: 'mom',
    component: 'mom_v',
    availableOn: 'Standard',
  },
  {
    id: 'widget_tasks',
    name: 'overview.Overview_quick_access_tasks',
    icon: <TaskIcon style={{ width: '24px' }} />,
    link: '/productivity/tasks?shouldCollapse=true',
    matomo: MATOMO_KEYS.TASKS,
  },
  {
    id: 'widget_timesheet',
    name: 'overview.Overview_quick_access_timesheet',
    icon: <TimesheetIcon style={{ width: '24px' }} />,
    link: '/people/timesheets?tab=myTimesheet&shouldCollapse=true',
    matomo: MATOMO_KEYS.TIMESHEET,
    module: 'timesheets',
    component: 'timesheets_v',
    availableOn: 'Standard',
  },
  {
    id: 'widget_project',
    name: 'overview.Overview_quick_access_project',
    icon: <ProjectIcon style={{ width: '24px' }} />,
    link: '/projects?shouldCollapse=true',
    matomo: MATOMO_KEYS.PROJECTS,
    module: 'projects',
    component: 'bl_health_v',
    availableOn: 'Standard'
  },
  {
    id: 'widget_approvals',
    name: 'overview.Overview_quick_access_approvals',
    icon: <ApprovalIcon style={{ width: '24px' }} />,
    link: '/processes/approvals/active?shouldCollapse=true',
    matomo: MATOMO_KEYS.APPROVALS,
    module: 'processes',
    component: 'int_memo_v',
    availableOn: 'Standard',
  },
  {
    id: 'widget_requests',
    name: 'overview.Overview_quick_access_requests',
    icon: <RequestIcon style={{ width: '24px' }} />,
    link: '/processes/requests/active?shouldCollapse=true',
    matomo: MATOMO_KEYS.REQUESTS,
    module: 'processes',
    component: 'int_memo_v',
    availableOn: 'Standard',
  },
  {
    id: 'widget_org',
    name: 'overview.Overview_quick_access_orgstructure',
    icon: <OrgstructureIcon style={{ width: '24px' }} />,
    link: '/people/org-structure?shouldCollapse=true',
    matomo: MATOMO_KEYS.ORG_STRUCTURE,
    module: 'org_structure',
    component: 'structure_v',
    availableOn: 'Standard',
  },
];
