import React from 'react';

import { ReactComponent as ListPokerface } from '../../assets/svg/list-pokerface.svg';
import { useStyles } from './styel';

const Empty = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.noDataCenter}>
      <ListPokerface style={{ width: '90px' }} />
      <p className={classes.noElementsText}>{title} </p>
    </div>
  );
};

export default Empty;
