import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  table: {
    width: 'inherit',
    marginTop: '12px',
    boxShadow: 'none',
    maxHeight: '100%',
    overflow: 'auto',
    margin: 0,
    border: '1px solid rgba(38, 40, 66, 0.08)',
    borderRadius: '12px',
    '& .home-MuiTableContainer-root': {
      border: 'none !important',
    },
  },
  text: {
    fontWeight: 'normal',
    textAlign: 'start',
    color: theme.palette.grey[500],
    fontSize: '14px',
    lineHeight: '18px',
    paddingBottom: '16px',
  },
}));
