import React, { useContext, useEffect, useState } from 'react';
import {
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Paper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Task } from '../../../interfaces/tasks';
import {
  TaskManagerFiltersContext,
  useCurrentUser,
  useStatusesStore,
} from '@dar-dms/utils';
import TaskItem from './TaskItem';

type Props = {
  tasks: Task[];
};

const useTaskCellStyles = makeStyles({
  cell: {
    padding: '0 !important',
    height: 'fit-content',
  },
  row: {
    border: 'none',
  },
});
export type Order = 'asc' | 'desc';
const TasksCollapse: React.FunctionComponent<Props> = ({ tasks }) => {
  const { statuses } = useStatusesStore();
  const taskCellClasses = useTaskCellStyles();
  const { order, orderBy } = useContext(TaskManagerFiltersContext);
  const session = useCurrentUser();
  const page = 0;
  const rowsPerPage = tasks.length;

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]?: number | string | Date },
    b: { [key in Key]?: number | string | Date }
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  return (
    <TableRow className={taskCellClasses.row}>
      <TableCell colSpan={12} className={taskCellClasses.cell}>
        <Collapse in timeout="auto" unmountOnExit>
          <TableContainer component={Paper}>
            <Table
              size="small"
              aria-label="tasks"
              style={{ tableLayout: 'fixed' }}
            >
              <TableBody>
                {stableSort(tasks as any[], getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((task: Task) => {
                    return (
                      <TaskItem
                        task={task}
                        key={task.id}
                        session={session}
                        statuses={statuses}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default TasksCollapse;
