import { environment } from '../environments/environment';
import { Project } from '../interfaces/projects';
import { apiClient } from './clients';

const getProjects = () => {
  return apiClient
    .get<Project[]>(`${environment.commsApiRoot}/task-manager/projects`)
    .then((res) => res.data);
};

export const projectService = {
  getProjects,
};
