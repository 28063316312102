import React, { useCallback, useContext, useEffect, useState } from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './Calendar.styles';
import { customTheme } from './CustomTheme';
import { add, eachDayOfInterval } from 'date-fns';
import {
  MeetingFilterContext,
  MeetingsListFilter,
  NewMeetingsList,
} from '@dar-dms/utils';
import { useLazyGetMeetingListQuery } from '../../store/reducers/meeting';
import { BehaviorSubject } from 'rxjs';

export const refetchObserver = new BehaviorSubject(false);

const Calendar = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const [
    getMeetings,
    { isFetching: loading, data: meetings = [], isSuccess },
  ] = useLazyGetMeetingListQuery();

  const {
    meetingFilter,
    setMeetingFilter,
    isFetching,
    setIsFetching,
    scrollInterval,
    setScrollInterval,
    setMoveToLine,
  } = useContext<any>(MeetingFilterContext);

  useEffect(() => {
    if (!meetingFilter) {
      return;
    }

    getMeetingByFilter();
  }, [meetingFilter]);

  useEffect(() => {
    const subscription = refetchObserver.subscribe((value) => {
      value && getMeetingByFilter();
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const getMeetingByFilter = () => {
    getMeetings({
      startDate: meetingFilter.startTime,
      endDate: meetingFilter.endTime,
    });
  };

  useEffect(() => {
    if (
      isSuccess &&
      new Date(meetingFilter.startTime).setHours(0, 0, 0, 0) ===
        new Date().setHours(0, 0, 0, 0)
    ) {
      setMoveToLine(true);
    }
  }, [isSuccess, meetingFilter?.startTime]);

  const onFilterChange = useCallback(
    (startTime: Date, endTime: Date, interval: Date[]) => {
      setMeetingFilter({
        startTime,
        endTime,
        interval,
      });
    },
    [setMeetingFilter]
  );

  const fetchData = useCallback(async () => {
    const startTime = meetingFilter && new Date(meetingFilter.startTime);
    const endTime = add(startTime, {
      days: page,
      hours: 23,
      minutes: 59,
      seconds: 59,
    });
    const interval = eachDayOfInterval({ start: startTime, end: endTime });

    getMeetings({ startDate: startTime, endDate: endTime });
    setPage(page + 1);
    setScrollInterval(interval);
    setIsFetching(false);
  }, [getMeetings, meetingFilter, page, setScrollInterval]);

  useEffect(() => {
    if (!isFetching) return;
    fetchData();
  }, [fetchData, isFetching]);

  return (
    <div className={classes.widgetContainer}>
      <h3 className={classes.title}>{t('overview.Overview_calendar')}</h3>
      <MuiThemeProvider theme={customTheme}>
        <div className={classes.calendarContainer}>
          <div>
            <MeetingsListFilter meetings={meetings} onChange={onFilterChange} />
          </div>
          <NewMeetingsList
            interval={scrollInterval}
            loading={loading}
            meetings={meetings}
            refetchData={getMeetingByFilter}
          />
        </div>
      </MuiThemeProvider>
    </div>
  );
};

export default Calendar;
