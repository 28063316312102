import React, {memo, useMemo, useEffect} from 'react';
import {Card, Box, Typography, Tooltip, Button} from '@mui/material';
import cn from 'classnames';
import {useHistory} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import shave from 'shave';

import {TaskRequestType, TicketDataType} from '../../../interfaces/processes';
import useTicket from './useTicket';
import {HtmlParser} from '../HtmlParser/HtmlParser';
import {TaskStatusIndicator} from '../TaskStatusIndicator/TaskStatusIndicator';

import useStyles from './useStyles';
import PriorityHigh from '../../../assets/svg/priority-high.svg';
import PriorityMedium from '../../../assets/svg/priority-medium.svg';
import AttachmentsIcon from '../../../assets/svg/request-attachments.svg';
import CommentsIcon from '../../../assets/svg/request-comments.svg';
import ClockIconOverdue from '../../../assets/svg/clock-icon-overdue.svg';
import ClockIconNormal from '../../../assets/svg/clock-icon.svg';
import SignatureIcon from '../../../assets/svg/signature_regular.svg';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRounded from '@mui/icons-material/ExpandLessRounded';

type Props = {
  request: TaskRequestType;
  type?: 'myRequest' | 'reviewedApproval' | 'approval' | 'draft';
  currentTab?: number;
  setMassApprove?: (ticketData: TicketDataType) => void;
  selectTicket?: (ticketData: TicketDataType) => void;
  selected?: { [key: string]: any };
  active?: boolean;
  onClick?: () => void;
};

export const TRUNCATED_FIELD_TYPES = ['multiLineText', 'singleLineText'];

export const TASK_STATUSES = {
  InProgress: {
    color: "#497CF6",
    translationKey: "form_components.votingResults.inProgress"
  },
  Completed: {
    color: "#2DB77B",
    translationKey: "task_statuses.completed"
  },
  Rework: {
    color: "#FFA530",
    translationKey: "task_statuses.rework"
  },
  Reject: {
    color: "#D6331F",
    translationKey: "task_statuses.reject"
  },
  Canceled: {
    color: "#D6331F",
    translationKey: "task_statuses.canceled"
  },
  Draft: {
    color: "#8B8C9E",
    translationKey: "task_statuses.draft"
  }
}

export const Ticket = memo(
  ({
    request,
    type = 'myRequest',
    setMassApprove,
    selectTicket,
    selected,
    currentTab,
    active = false,
    onClick,
  }: Props) => {
    const {t} = useTranslation('processesV2');

    const classes = useStyles();
    const history = useHistory();

    const {loading, error, ticketData, isCollapsable, isCollapsed, toggleCollapsedState} = useTicket({
      request,
      type,
      setMassApprove,
      onClick,
      currentTab,
    });

    const goToProcess = () => {
      history.push('/processes/process/' + ticketData.processInstanceId);
    };

    const processTitle = useMemo((): string => {
      if (!(ticketData?.processName || ticketData?.processDisplayName)) {
        return "";
      }

      return ticketData?.processDisplayName || ticketData?.processName;
    }, [ticketData?.processName, ticketData?.processDisplayName]);

    const status = useMemo(() => {
      const taskStatus = request.businessTask.taskStatus;
      if (!taskStatus) return null;

      return <Box display="flex" alignItems="center">
        <Box className={classes.statusIcon} bgcolor={TASK_STATUSES[taskStatus]?.color}></Box>
        <Typography>{t(TASK_STATUSES[taskStatus]?.translationKey)}</Typography>
      </Box>
    }, [request])

    const priority = useMemo(() => {
      if (!request.urgent) return;

      return (
        <Box display="flex" alignItems="center" gap={3}>
          <img className={classes.priorityIcon} src={request.urgent.urgent ? PriorityHigh : PriorityMedium} alt=""/>
          <Typography>
            {request.urgent.urgent ? t("form_components.urgent.high") : t("form_components.urgent.medium")}
          </Typography>
        </Box>
      );
    }, [request])

    useEffect(() => {
      shave(".summary-field-hint", 45, {spaces: false, character: "...:"})
      setTimeout(() =>
          shave(".summary-field-hint", 45, {spaces: false, character: "...:"})
        , 0);
    }, [error, loading, ticketData, currentTab]);

    const renderContent = useMemo(() => {
        if (loading) {
          return <>Loading...</>;
        }

        if (error) {
          return <div>{error}</div>;
        }

        if (ticketData) {
          return (
            <Box className={cn(classes.cardWrapper)} onClick={goToProcess}>
              {
                ticketData.isSignatureStep &&
                <div className={classes.ticketChip}>
                  <img src={SignatureIcon} alt={''}/>
                  <span>{t('task_data_view.signatureStep')}</span>
                </div>
              }

              <Box className={classes.cardContent}>
                <Box display="flex" alignItems="center" mb={2}>
                  {ticketData.iconPath && (
                    <img
                      src={ticketData.iconPath}
                      className={classes.cardContentHeaderIcon}
                      alt=""
                    />
                  )}

                  <Tooltip title={processTitle} classes={{
                    popper: classes.cardTooltipPopper,
                    tooltip: cn(classes.cardTooltip, classes.cardTooltipLongText)
                  }}>
                    <Typography className={classes.cardContentHeaderTitle}>{processTitle}</Typography>
                  </Tooltip>
                </Box>

                <Box mt={1}>
                  {ticketData.summaryType === 'description' && <div className={classes.processDescription}>
                    <Tooltip title={ticketData.processDescription} placement="bottom" arrow enterDelay={500} classes={{
                      popper: classes.cardTooltipPopper,
                      tooltip: cn(classes.cardTooltip, classes.cardTooltipLongText)
                    }}>
                      <span>{ticketData.processDescription}</span>
                    </Tooltip>
                  </div>}

                  {ticketData.summaryType === 'html' &&
                    <div className={classes.summaryHtmlWrapper}><HtmlParser code={ticketData.summary}/></div>}

                  {ticketData.summaryType === 'fields' && <div className={classes.summaryFieldsWrapper}>
                    {ticketData.summaryFields.map(({hint, value, fieldType, valuesCount}) =>
                      <div className={classes.summaryField}>
                        <Tooltip title={hint} placement="bottom" arrow enterDelay={500}
                                 classes={{popper: classes.cardTooltipPopper, tooltip: classes.cardTooltip}}>
                          <div className={classes.summaryFieldHintWrapper}>
                           <span className={cn("summary-field-hint", classes.summaryFieldHint)}>
                            {hint}:
                           </span>
                          </div>
                        </Tooltip>

                        {TRUNCATED_FIELD_TYPES.includes(fieldType) && !!value
                          ?
                          <Tooltip title={value} placement="top" arrow enterDelay={500} classes={{
                            popper: classes.cardTooltipPopper,
                            tooltip: cn(classes.cardTooltip, classes.cardTooltipLongText)
                          }}>
                            <span className={cn(classes.summaryFieldValue, {[classes.summaryFieldEmptyValue]: !value})}>
                              {value ? value : t('form_components.readOnly.emptyValue')}
                            </span>
                          </Tooltip>
                          :
                          <span className={cn(classes.summaryFieldValue, {[classes.summaryFieldEmptyValue]: !value})}>
                            {value ? value : t('form_components.readOnly.emptyValue')}
                            {valuesCount > 1 ?
                              <span className={classes.summaryFieldValueCount}>{" "}(+{valuesCount - 1})</span> : null}
                          </span>
                        }
                      </div>,
                    )
                    }
                  </div>
                  }
                </Box>

                <Box display="flex" flexDirection="column">
                  {ticketData.urgent && (
                    <Typography className={cn(classes.cardContentFooterTitle, classes.cardContentUrgentTitle)}>
                      {t('form_components.urgent.title')}: {ticketData.reasonOfUrgent}
                    </Typography>
                  )}

                  <Box display="flex" flexDirection="row" gap={8} pt={2}>
                    {priority}
                    {status}
                  </Box>

                  <Box display="flex" alignItems="center" pt={2}>
                    <Typography className={classes.cardContentFooterTitle}>{ticketData.initiator}</Typography>

                    <div
                      className={classes.cardContentFooterButton}
                    >
                      <img
                        className={classes.cardContentFooterIcon}
                        src={AttachmentsIcon}
                        alt="attachments"
                      />

                      <Typography className={classes.cardContentFooterTitle}>
                        {ticketData.attachmentCount}
                      </Typography>
                    </div>

                    <div
                      className={classes.cardContentFooterButton}
                    >
                      <img
                        className={classes.cardContentFooterIcon}
                        src={CommentsIcon}
                        alt="comments"
                      />

                      <Typography className={classes.cardContentFooterTitle}>
                        {ticketData.commentsSize}
                      </Typography>
                    </div>

                    <Box className={classes.cardContentDueDate}>
                      <img
                        className={cn(classes.cardContentFooterIcon)}
                        src={ticketData.isTimerOver ? ClockIconOverdue : ClockIconNormal}
                        alt=""
                      />
                      <Typography
                        className={cn(classes.cardContentFooterTitle, {[classes.cardContentFooterTitleOverdue]: ticketData.isTimerOver})}>
                        {
                          ticketData.isTimerOver
                            ? t('task_data_view.timerOverdue')
                            : t('task_data_view.timerDueDate')
                        }: {ticketData.formattedDueDate || '-'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className={classes.cardStatus}>
                <TaskStatusIndicator
                  step={ticketData.stepNumber}
                  processSysName={ticketData.processSysName}
                  parallelBranchesName={ticketData.parallelBranchesName}
                  parallelBranchesStepperOrder={ticketData.parallelBranchesStepperOrder}
                  totalSteps={ticketData.stepsTotal}
                  isCompleted={ticketData.completed}
                  isCancelled={ticketData.cancelled}
                  isRejected={ticketData.reject}
                  stepsList={ticketData.stepsList}
                  hasCondition={ticketData.hasCondition}
                />
              </Box>
            </Box>
          );
        }

        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [error, loading, ticketData, priority, status]
    );

    return (
      <Box className={cn(
        classes.wrapper,
        {
          [classes.wrapperUnread]: ticketData?.unread,
        },
      )}
      >

        <Card
          className={cn(classes.card, {
            [classes.cardActive]: active,
            [classes.cardUrgent]: ticketData?.urgent,
            [classes.cardCollapsed]: isCollapsed,
          })}
          elevation={0}
          id={type + ticketData?.id}
        >
          {renderContent}
        </Card>

        {isCollapsable &&
          <Button
            variant="text"
            className={classes.showMoreButton}
            onClick={toggleCollapsedState}
            endIcon={isCollapsed ? <ExpandMoreRounded/> : <ExpandLessRounded/>}>
            {t(isCollapsed ? 'buttons.show_more' : 'buttons.show_less')}
          </Button>
        }
      </Box>
    );
  }
);
