import { makeStyles } from '@material-ui/core';

export const useStyles = () =>
  makeStyles((theme) => ({
    container: {
      display: 'grid',
      gridTemplateColumns: 'initial',
      gridAutoFlow: 'column',
      gridAutoColumns: '1fr',
      gridGap: '12px',
    },
    widget: {
      width: '100%',
      height: '100%',
      background: '#FFFFFF',
      border: '1px solid rgba(38, 40, 66, 0.08)',
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.04)',
      borderRadius: '10px',
      padding: '12px 20px',
    },
    text: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 500,
      marginBottom: '12px',
      marginTop: '0px',
    },
    accessItem: {
      position: 'relative',
      maxWidth: '160px',
      width: '100%',
      maxHeight: '64px',
      background: '#F7F8FC;',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid rgba(38, 40, 66, 0.08)',
      borderRadius: '6px',
      padding: '8px',
      '&:hover': {
        background:
          'linear-gradient(0deg, rgba(38, 40, 66, 0.04), rgba(38, 40, 66, 0.04)), #F7F8FC',
      },
      '&:active': {
        background:
          'linear-gradient(0deg, rgba(38, 40, 66, 0.08), rgba(38, 40, 66, 0.08)), #F7F8FC',
      },
    },
    icon: {
      width: '24px',
      height: '24px',
    },
    iconDisabled: {
      filter: 'grayscale(100%)',
      opacity: '0.7',
    },
    iconName: {
      marginTop: '8px',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    iconNameDisabled: {
      color: '#C0C1D1',
    },
    link: {
      textDecoration: 'none',
      color: '#101F2B'
    },
    linkDisabled: {
      cursor: 'default',
    },
    lockIcon: {
      position: 'absolute',
      width: 20,
      height: 20,
      top: -10,
      right: -10,
    },
  }));
