import {useState, useEffect, useMemo, useContext} from 'react';
import i18n from 'i18next';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {useUsersStore} from "@dar-dms/utils";

import {TaskRequestSummaryField, TaskRequestType, TicketDataType} from '../../../interfaces/processes';
import {workspace} from "../../../utils/workspace";
import { GlossaryContext } from '../GlossaryContext';

const MALFORMED_TASK_ERROR = 'Malformed task';

const LOCALIZED_TIME_UNITS = {
  'ru': {
    day: 'д',
    hour: 'ч',
  },
  'en': {
    day: 'd',
    hour: 'h',
  },
  'kz': {
    day: 'к',
    hour: 'c',
  },
  'de': {
    day: 's',
    hour: 't',
  },
  'id': {
    day: 'h',
    hour: 'j',
  },
  default: {
    day: 'd',
    hour: 'h',
  },
};

export const CARD_COLLAPSE_HEIGHT_POINT = 148;

type Props = {
  request: TaskRequestType;
  type?: 'myRequest' | 'reviewedApproval' | 'approval' | 'draft';
  setMassApprove?: (ticketData: TicketDataType) => void;
  onClick?: () => void;
  currentTab?: number;
};

const useTicket = ({request, type, setMassApprove, onClick, currentTab}: Props) => {
  const { allUsers } = useUsersStore();
  const { t } = useTranslation('processesV2');
  const glossary = useContext(GlossaryContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [ticketData, setTicketData] = useState<TicketDataType | null>(null);
  const [isCollapsable, setCollapsable] = useState<boolean>(false);
  const [isCollapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    if (ticketData) {
      const cardElement = document.getElementById(type + ticketData.id);
      if (cardElement) {
        const cardHeight = cardElement?.offsetHeight;
        if (cardHeight > CARD_COLLAPSE_HEIGHT_POINT) {
          setCollapsable(true);
          setCollapsed(true);
        } else {
          setCollapsable(false);
          setCollapsed(false);
        }
      }
    }
  }, [ticketData, currentTab, type]);

  const toggleCollapsedState = () => {
    setCollapsed(v => !v);
  }

  const localizedSummary = useMemo(() => {
    if (!request) {
      return '';
    }

    let summaryText = request.summary || '';
    if (!glossary) {
      return summaryText;
    }

    while (summaryText.includes('${')) {
      const startPoint = summaryText.indexOf('${');
      const endPoint = summaryText.indexOf('}');
      if (startPoint > 0 && endPoint > 0) {
        const replaceString = summaryText.substring(startPoint, endPoint + 1);
        const initialValue = replaceString.substring(
          2,
          replaceString.length - 1
        );
        const glossaryValueKey = glossary[initialValue];
        summaryText = summaryText.replace(
          replaceString,
          i18n.t(`glossary.${glossaryValueKey}`, {
            ns: 'processesV2',
            defaultValue: initialValue,
          })
        );
      } else {
        summaryText = summaryText.replace('${', '');
      }
    }

    return summaryText;
  }, [request, glossary]);

  const formatTimeFromMinutes = (minutes: number, locale = 'en') => {
    const hTotal = Math.floor(minutes / 60);
    const m = minutes % 60;
    const d = Math.floor(hTotal / 24);
    const h = hTotal % 24;
    if (d) {
      const localizedUnits = LOCALIZED_TIME_UNITS[locale] || LOCALIZED_TIME_UNITS['default'];
      return `${d}${localizedUnits.day}:${(h + '').padStart(2, '0')}${localizedUnits.hour}`
    }

    return `${h}:${(m + '').padStart(2, '0')}`
  }

  const getExecutionDeadlineStatus = (deadlineDate, taskEndDate) => {
    if (!deadlineDate) {
      return {
        formattedDueDate: null,
        isTimerOver: false,
      }
    }

    const deadline = moment(deadlineDate);
    const isTimerOver = taskEndDate ? deadline.isBefore(moment(taskEndDate)) : deadline.isBefore(moment(Date.now()));
    return {
      formattedDueDate: deadline.format('DD.MM.Y, HH:mm'),
      isTimerOver,
    }
  }

  const getFieldDisplayValue = (fieldData: TaskRequestSummaryField) => {
    if (['glossaryMultiSelect', 'glossary'].includes(fieldData?.type)) {
      if (!fieldData?.localization) {
        return fieldData?.value as string;
      }
      return fieldData?.localization[i18n.language] || fieldData?.localization['en'] || fieldData?.localization['default'];
    }

    if (fieldData?.type === 'systemGlossary') {
      if (!fieldData?.localization) {
        return fieldData?.value as string;
      }
      return fieldData?.localization[i18n.language] || fieldData?.localization['en'] || fieldData?.value;
    }

    return fieldData?.value as string;
  }

  const getProcessDescriptionDisplayValue = (descriptionLocalization: {[key: string]: string}, processSysName: string) => {
    if (!descriptionLocalization) {
      return t(`constructor-${processSysName}.description`, {defaultValue: ''});
    }
    return descriptionLocalization[i18n.language] || descriptionLocalization['en'] || descriptionLocalization['default'];
  }

  const isUnread = useMemo(() =>
      (type === 'myRequest' && request?.businessTask?.badges?.initiator?.taskRead) ||
      (type === 'approval' && request?.businessTask?.badges?.assignee?.taskRead)
    , [type, request]);

  const hasUnreadComments = useMemo(() =>
      (type === 'myRequest' && request?.businessTask?.badges?.initiator?.commentRead) ||
      (type === 'approval' && request?.businessTask?.badges?.assignee?.commentRead)
    , [type, request])

  const hasUnreadAttachments = useMemo(() =>
      (type === 'myRequest' && request?.businessTask?.badges?.initiator?.attachmentRead) ||
      (type === 'approval' && request?.businessTask?.badges?.assignee?.attachmentRead)
    , [type, request])

  const initiatorFullName = useMemo(() => {
    const {
      businessTask: {
        initiator,
      },
    } = request;

    if (initiator === 'company' || initiator === '${company}') {
      return workspace?.organization?.shortName || workspace?.organization?.name;
    }

    const initiatorUser = allUsers.find(user => user.id === initiator);
    return initiatorUser?.displayName
  }, [request, allUsers]);

  useEffect(() => {
    try {
      const {
        businessTask: {
          taskId: id,
          companyId,
          applicationNumber,
          initiator = '',
          processInstanceId = '',
          processDefinitionName: processName = '',
          processSysName = '',
          taskCompleted: completed = false,
          taskEndDate,
          errorStatus = false,
          dueDate,
          dueDateTemplate,
          progressBar,
          taskStatus
        },
        commentsSize = 0,
        attachmentCount = 0,
        currentAction: {
          name: stepName = '',
          sysName: stepSysName = '',
          massApprove = false,
        },
        processIconPath: iconPath,
        urgent: {
          urgent = false,
          reason: reasonOfUrgent = '',
        },
        summaryFields = [],
        descriptionLocalization = {},
        hasDocumentSign = false
      } = request;

      const {
        currentParallelSteps = null,
        currentParallelStepsStepperOrder = 0,
        stepsDetails = [],
        hasCondition = false,
        completedStepOrder = 0,
        stepsCount = 0,
      } = progressBar ?? {};

      const timerStatus = getExecutionDeadlineStatus(dueDateTemplate, taskEndDate);

      const isEmptySummary = !summaryFields?.length && (request?.summary || '')?.replace('<p>', '').replace('</p>', '').trim() === '';
      const summaryType = isEmptySummary
        ? 'description'
        : summaryFields?.length
          ? 'fields'
          : 'html';

      const ticketInfo: TicketDataType = {
        id,
        companyId,
        processInstanceId,
        applicationNumber,
        initiator: initiatorFullName || initiator,
        processName,
        processSysName,
        processDisplayName: t(`constructor-${processSysName}.name`, {defaultValue: processName}),
        completed,
        cancelled: completed && errorStatus,
        massApprove: massApprove || type === 'draft',
        stepName,
        stepDisplayName: t(`constructor-${processSysName}.actions.${stepSysName}.name`, {defaultValue: stepName}),
        stepNumber: completedStepOrder,
        stepsTotal: stepsCount,
        stepsList: stepsDetails,
        iconPath,
        commentsSize,
        attachmentCount,
        summaryType,
        summary: localizedSummary || '',
        processDescription: getProcessDescriptionDisplayValue(descriptionLocalization, processSysName),
        summaryFields: summaryFields
        ?.sort((a,b) => a.fieldOrder - b.fieldOrder)
        ?.map(fieldData => ({
          fieldType: fieldData.type,
          hint: t(`constructor-${processSysName}.attributes.${fieldData.sysName}.hint`, {defaultValue: fieldData.name}),
          value: getFieldDisplayValue(fieldData) as string,
          valuesCount: +fieldData?.valuesCount || 0,
        })),
        urgent,
        reasonOfUrgent,
        isOverdue: taskEndDate ? moment(dueDate).isBefore(moment(taskEndDate)) : moment(dueDate).isBefore(moment()),
        rework: stepSysName?.toLowerCase().includes('rework'),
        reject: taskStatus === 'Reject',
        taskStatus,
        unread: isUnread,
        unreadComments: hasUnreadComments,
        unreadAttachments: hasUnreadAttachments,
        hasCondition,
        parallelBranchesName: currentParallelSteps,
        parallelBranchesStepperOrder: currentParallelStepsStepperOrder,
        isSignatureStep: hasDocumentSign,
        ...timerStatus,
      };

      setTicketData(ticketInfo);
      setError('');
    } catch (error) {
      setError(MALFORMED_TASK_ERROR);
    } finally {
      setLoading(false);
    }
  }, [localizedSummary, initiatorFullName, hasUnreadComments, hasUnreadAttachments, request]);

  return {
    loading,
    error,
    ticketData,
    isCollapsable,
    isCollapsed,
    toggleCollapsedState
  };
};

export default useTicket;
