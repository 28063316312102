export const environment = {
  production: true,
  envName: 'prod',
  commsApiRoot: 'https://dms-comms.dar.io/api',
  overviewApiRoot: 'https://dms.dar.io/api/v1/main-api',
  bpmApiRoot: 'https://dms.dar.io/api/v1/bpm-main-api/process-info',
  layoutApi: 'https://dms-layout.dar.io/api',
  dmsBaseApi: 'https://dms.dar.io/api/v1',
  darHoldingId: 'bb4fc077-3c81-4419-a4d8-c2466aa12b42',
};
