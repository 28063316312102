import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Task } from '../../../interfaces/tasks';
type TaskKeys = keyof Task | 'menu';

type HeadCell = {
  id: TaskKeys;
  align?: 'right' | 'center' | 'left' | 'inherit' | 'justify' | undefined;
  label: string;
  width: string;
  sortable: boolean;
  style?: React.CSSProperties;
};

type TasksListHeadProps = {
  onRequestSort?: (
    event: React.MouseEvent<unknown>,
    property: TaskKeys
  ) => void;
  order?: 'asc' | 'desc';
  orderBy?: string;
};

export const useStyles = makeStyles(() =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    cell: {
      padding: 0,
      margin: 0,
      height: '32px',
      paddingTop: '7px',
      paddingBottom: '7px',

      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
    },
    overflowText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
    },
    label: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#8B8C9E',

      '&>svg': {
        opacity: 1,
      },
    },
    avatar: {
      width: '24px !important',
      height: '24px !important',
    },
    item: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flexDirection: 'column',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#262842',
    },
    avatarCell: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

const TasksListHead = (props: TasksListHeadProps) => {
  const { t } = useTranslation();
  const headCells: HeadCell[] = [
    {
      id: 'title',
      label: t('overview.title'),
      width: '50%',
      sortable: true,
    },
    {
      id: 'endTime',
      align: 'center',
      label: t('overview.due_date'),
      width: '25%',
      sortable: true,
    },
    {
      id: 'assigneeId',
      align: 'center',
      label: t('tasks.alltasks_filter2', { ns: 'productivity' }),
      width: '25%',
      sortable: false,
    },
  ];
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: TaskKeys) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
            className={classes.cell}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={
                headCell.sortable ? createSortHandler(headCell.id) : undefined
              }
              className={classes.label}
              IconComponent={ArrowDropDownIcon}
              hideSortIcon={!headCell.sortable}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TasksListHead;
