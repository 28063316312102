import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  useUsersStore,
  Header,
} from '@dar-dms/utils';
import {
  TaskManagerFiltersProvider,
  TaskManagerModalsProvider,
} from '@dar-dms/utils';

import Task from '../components/Task/Task';
import QuickAccess from '../components/QuickAccess';
import Calendar, { refetchObserver } from '../components/Calendar/Calendar';
import Processes from '../components/Processes/Processes';
import { takeBreadcrumbs } from './utils';
import { billingService } from '@dar-dms/topbar';
import UpgradeProcesses from '../components/UpgradeProcesses/UpgradeProcesses';

const useStyles = () =>
  makeStyles((theme) => ({
    title: {
      width: '100%',
      height: '70px',
      background: '#FFFFFF',
      fontSize: '24px',
      lineHeight: '32px',
      padding: '19px 24px',
    },
    page: {
      width: '100%',
      background: '#F7F8FC',
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      maxHeight: 'calc(100vh - 49px)',
      padding: '20px 20px',
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      gridTemplateRows: 'auto 1fr 1fr',
      gridAutoRows: 'initial',
      gridTemplateAreas: `
      "calendar rightSidebar"
      "calendar processes"
      "calendar tasks"
    `,
      gridGap: '20px',
    },
    calendar: {
      gridArea: 'calendar',
      minWidth: '233px',
      maxWidth: '326px',
    },
    tasks: {
      gridArea: 'tasks',
      minWidth: '486px',
      minHeight: '248px',
    },
    processes: {
      gridArea: 'processes',
      minWidth: '486px',
      minHeight: '248px',
    },
    rightSidebar: {
      gridArea: 'rightSidebar',
      width: '100%',
      height: '100%',
      minWidth: '233px',
      maxWidth: 'initial',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    quickAccess: {
      width: '100%',
      height: '88px',
    },
    newsFeed: {
      height: '100%',
    },
  }));

const MainPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles()();
  const match = useRouteMatch();
  const breadCrumbs = takeBreadcrumbs(t)[match.path] || [];
  const [subscriptionTrigger, setSubscriptionTrigger] = useState<boolean>(false);
  const { getUsers } = useUsersStore();

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    // triggering subscription change check to update the UI
    const subscription = billingService.subscription$?.subscribe(() => {
      setSubscriptionTrigger((prev) => !prev);
    });
    return () => {
      subscription?.unsubscribe?.();
    };
  }, [])

  const hasSubscription = useMemo(() => {
    try {
      return billingService.getPermission({ module: 'processes', component: 'int_memo_v' });
    } catch (e) {
      return false;
    }
  }, [subscriptionTrigger]);

  const onCreate = () => {
    refetchObserver.next(true);
  };

  return (
    <div className={classes.page}>
      <Header breadcrumbs={breadCrumbs} createMeetingCallback={onCreate} />
      <div className={classes.container}>
        <div className={classes.calendar}>
          <Calendar />
        </div>
        <div className={classes.tasks}>
          <TaskManagerModalsProvider>
            <TaskManagerFiltersProvider>
              <Task />
            </TaskManagerFiltersProvider>
          </TaskManagerModalsProvider>
        </div>
        <div className={classes.processes}>
          {hasSubscription ? <Processes /> : <UpgradeProcesses />}
        </div>
        <div className={classes.rightSidebar}>
          <div className={classes.quickAccess}>
            <QuickAccess subscriptionTrigger={subscriptionTrigger}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
