import React, { memo, useMemo } from 'react';
import DOMPurify from 'dompurify';
import { Typography } from '@material-ui/core';

import useStyles from './useStyles';

type Props = {
  code: string;
};

const isStringHtmlCode = (str) =>
  /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(str);

export const HtmlParser = memo(({ code }: Props) => {
  const classes = useStyles();

  const isHtmlCode = useMemo((): boolean => isStringHtmlCode(code), [code]);

  const protectedHtmlCode = useMemo(
    (): string => (isHtmlCode ? DOMPurify.sanitize(code) : ''),
    [isHtmlCode, code]
  );

  if (!isHtmlCode) {
    return <Typography>{code}</Typography>;
  }

  return (
    <div
      className={classes.content}
      dangerouslySetInnerHTML={{ __html: protectedHtmlCode }}
    />
  );
});
