import { Task, TasksPaginatedList, TaskStatus } from '../interfaces/tasks';
import { environment } from '../environments/environment';
import { apiClient } from './clients';

export const getAllTasks = async (params, byMe: boolean) => {
  const res = await apiClient.get<TasksPaginatedList>(
    `${environment.commsApiRoot}/task-manager/tasks`,
    {
      params,
    }
  );
  return byMe
    ? res.data.items.filter((task) => task.assignee_id !== params.creator_id)
    : res.data.items;
};

export const getTasksBatch = (data: number[] = []) => {
  return apiClient
    .post<Task[]>(
      `${environment.commsApiRoot}/meeting-manager/mom/batch/task`,
      data
    )
    .then((res) => res.data);
};

export const listStatus = () => {
  return apiClient
    .get<TaskStatus[]>(`${environment.commsApiRoot}/task-manager/statuses`, {
      params: {
        q: {
          brand_id: 'DMS',
        },
      },
    })
    .then((res) => res.data);
};

export const getCompanyId = () => {
  try {
    return JSON.parse(localStorage.getItem('workspace'))?.id;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const useWorkspace = () => {
  if (
    localStorage.getItem('workspace') === 'undefined' ||
    !localStorage.getItem('workspace')
  ) {
    return null;
  } else {
    return JSON.parse(localStorage.getItem('workspace'));
  }
};
