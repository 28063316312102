import TaskTable, { hideStatuses } from './TaskTable/TaskTable';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useStyles } from './Task.styles';
import { useStyles as useProcStyles } from '../Processes/Processes.styles';
import { useTranslation } from 'react-i18next';
import {
  TaskManagerFiltersContext,
  useCurrentUser,
  useTasksStore,
  useStatusesStore,
} from '@dar-dms/utils';
import { Tab, Tabs } from '@dartech/dms-ui';
import TabPanel from '../../components/TabPanel/TabPanel';
import { ReactComponent as ListPokerface } from '../../assets/svg/list-pokerface.svg';
import { projectService } from '../../services/projects';
import Empty from '../Empty';

const Task = () => {
  const classes = useStyles();
  const procClasses = useProcStyles();
  const { t } = useTranslation();
  const refTitle = useRef(null);
  const refWidget = useRef(null);
  const refTabs = useRef(null);
  const [tab, setTab] = useState(0);
  const { tasks } = useTasksStore();
  const { getStatuses } = useStatusesStore();

  const taskFiltered = useMemo(() => {
    return tasks?.filter(
      (task) => !hideStatuses.includes(task.status.toUpperCase())
    );
  }, [tasks]);

  const { handleChangeFilter } = useContext(TaskManagerFiltersContext);
  const session = useCurrentUser();
  const [filteredBy, setFilteredBy] = useState('Assigned to Me');

  useEffect(() => {
    projectService.getProjects().then((res) => {
      getStatuses(res.map((project) => project.id));
    });
  }, []);

  useEffect(() => {
    if (filteredBy === 'Assigned to Me' && session) {
      handleChangeFilter('assignedToMe', session);
    }
  }, [session]);
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTab(newValue);

    switch (newValue) {
      case 0:
        setFilteredBy('Assigned to Me');
        handleChangeFilter('assignedToMe', session);
        break;
      case 1:
        setFilteredBy('Assigned by Me');
        handleChangeFilter('assignedByMe', session);

        break;
    }
  };

  return (
    <div className={procClasses.widget} ref={refWidget}>
      <h3 className={procClasses.title} ref={refTitle}>
        {t('overview.Overview_tasks')}
      </h3>
      <div className={procClasses.tabsWrapper} ref={refTabs}>
        <Tabs
          indicatorColor="primary"
          className={procClasses.tabs}
          value={tab}
          onChange={handleChange}
          aria-label="task filters"
        >
          <Tab label={t('overview.Overview_tasks_filter2')} className={procClasses.tab} value={0} sx={{ border: 0, height: '41px'}}/>
          <Tab label={t('overview.Overview_tasks_filter3')} className={procClasses.tab} value={1} sx={{ border: 0, height: '41px'}}/>
        </Tabs>
      </div>
      <TabPanel
        value={0}
        index={0}
        className={taskFiltered.length ? classes.tabpanel : ''}
      >
        {taskFiltered.length ? (
          <TaskTable />
        ) : (
          <Empty title={t('overview.Overview_list_of_tasks')} />
        )}
      </TabPanel>
    </div>
  );
};
export default Task;
