import { environment } from '../environments/environment';
import { Project } from '../interfaces/projects';
import { apiClient } from './clients';
import { getCompanyId } from './tasks';

const getProjects = () => {
  const companyId = getCompanyId();
  return apiClient
    .get<Project[]>(`${environment.commsApiRoot}/task-manager/projects`, {
      params: {
        workspaceId: companyId,
      },
    })
    .then((res) => res.data);
};

export const projectService = {
  getProjects,
};
