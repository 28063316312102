import React, { useEffect, useRef, useState } from 'react';
import { Tab, Tabs } from '@dartech/dms-ui';
import { useStyles } from './Processes.styles';
import TabPanel from '../../components/TabPanel/TabPanel';
import {
  getApprovals,
  getHoldingSpecificProcessesTranslations,
  getProcessesTranslations,
  getRequests,
} from '../../services/processes';
import ProcessesList from './ProcessesList';
import { getCompanyId } from '../../services/tasks';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import i18next from 'i18next';
import { GlossaryContext } from './GlossaryContext';
import { useGlossary } from './useGlossary';
import styled from '@mui/styles/styled';

const StyledTabs = styled(Tabs)({
  border: 'none',
  position: 'relative',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '18px',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-6px',
    left: '0',
    width: '100%',
    height: '46px',
    borderBottom: '1px solid #E0E3EA',
  },
  '& .Mui-selected': {
    height: '4px',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '3px',
      background: '#007F9B',
    },
  },
});

const StyledTab = styled(Tab)({
  color: '#A0A3B9',
  '&.Mui-selected': {
    height: '41px',
    color: 'inherit',
    fontWeight: 500,
    border: 0,
  },
});

const Processes = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const companyId = getCompanyId();
  const [tab, setTab] = useState(0);
  const [approvals, setApprovals] = useState([]);
  const [requests, setRequests] = useState([]);
  const [approvalsPageNum, setApprovalsPageNum] = useState(0);
  const [requestsPageNum, setRequestsPageNum] = useState(0);
  const pageSize = 20;
  const [totalApprovals, setTotalApprovals] = useState(0);
  const [totalRequests, setTotalRequests] = useState(0);
  const [isApprovalsLoading, setIsApprovalsLoading] = useState(false);
  const [isRequestsLoading, setIsRequestsLoading] = useState(false);
  const refTitle = useRef(null);
  const refWidget = useRef(null);
  const refTabs = useRef(null);

  const glossary = useGlossary();

  const hasMore = (
    page: number,
    limit: number,
    total: number,
    isLoading: boolean
  ) => {
    const startIndex = page * limit + 1;
    return (isLoading && total === 0) || startIndex < total;
  };

  useEffect(() => {
    getHoldingSpecificProcessesTranslations({
      lang: i18n.language,
    }).then((data) => {
      i18next.addResourceBundle(i18n.language, 'processesV2', data, true);
    });
  }, [companyId]);

  useEffect(() => {
    setIsApprovalsLoading(true);
    getApprovals({
      pageNumber: approvalsPageNum,
      pageSize: pageSize,
      companyId: companyId,
    }).then((data) => {
      if (
        Array.isArray(data?.content) &&
        typeof data?.totalElements === 'number'
      ) {
        setApprovals((oldRequests) => [...oldRequests, ...data?.content]);
        setTotalApprovals(data?.totalElements);
      }
      setIsApprovalsLoading(false);
    });
  }, [approvalsPageNum, companyId, pageSize]);

  useEffect(() => {
    setIsRequestsLoading(true);
    getRequests({
      pageNumber: requestsPageNum,
      pageSize: pageSize,
      companyId: companyId,
    }).then((data) => {
      if (
        Array.isArray(data?.content) &&
        typeof data?.totalElements === 'number'
      ) {
        setRequests((oldRequests) => [...oldRequests, ...data?.content]);
        setTotalRequests(data?.totalElements);
      }
      setIsRequestsLoading(false);
    });
  }, [requestsPageNum, pageSize, companyId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <GlossaryContext.Provider value={glossary}>
      <div className={classes.widget} ref={refWidget}>
        <h3 className={classes.title} ref={refTitle}>
          {t('overview.Overview_processes')}
        </h3>
        <div className={classes.tabsWrapper} ref={refTabs}>
          <StyledTabs
            indicatorColor="primary"
            value={tab}
            onChange={handleTabChange}
            aria-label="task filters"
          >
            {[
              {
                value: 0,
                label:
                  t('overview.Overview_processes1') +
                  (approvals.length ? ` (${totalApprovals})` : ''),
              },
              {
                value: 1,
                label:
                  t('overview.Overview_processes2') +
                  (requests.length ? ` (${totalRequests})` : ''),
              },
            ].map(({ value, label }) => (
              <StyledTab label={label} value={value} sx={{ border: 0}} />
            ))}
          </StyledTabs>
        </div>
        <TabPanel
          value={tab}
          index={0}
          className={approvals.length ? classes.panel : ''}
        >
          <ProcessesList
            type="approvals"
            processes={approvals}
            currentTab={tab}
            nextFunction={() => {
              setApprovalsPageNum(approvalsPageNum + 1);
            }}
            hasMore={hasMore(
              approvalsPageNum,
              pageSize,
              totalApprovals,
              isApprovalsLoading
            )}
          />
        </TabPanel>
        <TabPanel
          value={tab}
          index={1}
          className={requests.length ? classes.panel : ''}
        >
          <ProcessesList
            type="requests"
            processes={requests}
            currentTab={tab}
            nextFunction={() => {
              setRequestsPageNum(requestsPageNum + 1);
            }}
            hasMore={hasMore(
              requestsPageNum,
              pageSize,
              totalRequests,
              isRequestsLoading
            )}
          />
        </TabPanel>
      </div>
    </GlossaryContext.Provider>
  );
};

export default Processes;
