import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  taskContainer: {
    background: '#FFFFFF',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid rgba(38, 40, 66, 0.08)',
    borderRadius: '10px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.04)',
    height: '100%',
    position: 'relative',
  },
  noDataCenter: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    justifyContent: 'center',
    // position: 'absolute', REMOVED, because it was causing the no data message to be positioned incorrectly
    top: 0,
    left: 0,
    transform: 'translateY(50%)',
  },
  title: {
    fontSize: '16px',
    lineHeight: '20px',
    color: '#465564',
  },
  tabpanel: {
    height: 'calc(100% - 83px)',
  },
}));
