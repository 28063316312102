import { AxiosResponse } from 'axios';
import { authService } from '@dar-dms/topbar';

import { commonExceptionHandler, addTrackingHeaders } from './handlers';

declare module 'axios' {
  export interface AxiosRequestConfig {
    bypassAlertOnErrorCodes?: string[];
  }
}

export const apiClient = authService.createHttpClient();
apiClient.interceptors.request.use(addTrackingHeaders);
apiClient.interceptors.response.use((response: AxiosResponse) => response, commonExceptionHandler);
