import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  customCell: {
    height: '60px',
  },

  widgetContainer: {
    background: '#FFFFFF',
    border: '1px solid rgba(38, 40, 66, 0.08)',
    borderRadius: '10px',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.04)',
  },
  title: {
    fontSize: '16px',
    margin: '20px 20px 0 20px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  calendarContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));
