import { UserAvatar, useUsersStore } from '@dar-dms/utils';
import {Box, TableCell, TableRow, Tooltip} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Task, TaskStatus, User } from '../../../interfaces/tasks';
import { useStyles } from './TasksListHead';
import { ReactComponent as WarningIcon } from '../../../assets/svg/warning_regular.svg';
import parse from 'html-react-parser';


type Props = {
  task: Task;
  session: User;
  statuses: TaskStatus[];
};

const TaskItem: React.FunctionComponent<Props> = ({
  task,
  session,
  statuses,
}) => {
  const classes = useStyles();

  const taskLink = useMemo(() => `/productivity/tasks/${task.id}`, [task.id]);
  const [showTooltip, setShowTooltip] = useState(false);

  const status = useMemo(() => {
    return statuses.find(({ id }) => id === task?.statusId);
  }, [statuses, task?.statusId]);

  const { t } = useTranslation();

  const { allUsers } = useUsersStore();

  const user = useMemo(
    () => allUsers.find((item: User) => item.id === task.assigneeId) as User,
    [allUsers, task.assigneeId]
  );

  const isOverdue = useMemo(() => {
    if (task.endTime) {
      return moment(task.endTime).isBefore(moment());
    }
    return false;
  }, [task.endTime]);

  return (
    <TableRow key={task.id}>
      <TableCell
        id={'title_cell_' + task.id}
        width="50%"
        align="left"
        className={classes.cell}
      >
        <Link
          style={{ textDecoration: 'none' }}
          to={{
            pathname: taskLink,
          }}
        >
          <Box className={classes.item}>
            <Tooltip
              title={
                showTooltip ? (
                    <div>{parse(task.title)}</div>
                ) : (
                  ''
                )
              }
              placement="top"
              enterNextDelay={200}
            >

            <div  ref={(ref) => {
              if (!ref) return;
              const isOverflowing = ref.scrollWidth > ref.clientWidth;
              setShowTooltip(isOverflowing);
            }}
                  className={classes.overflowText}>
              {parse(task.title)}
            </div>
            </Tooltip>
            <div style={{ color: '#8B8C9E' }}>
              <FiberManualRecordIcon
                fontSize="small"
                style={{
                  fill: status?.color,
                  fontSize: '8px',
                  marginRight: '4px',
                }}
              />
              {t(status?.i18n) || status?.name}
            </div>
          </Box>
        </Link>
      </TableCell>
      <TableCell
        width="25%"
        align="center"
        className={classes.cell}
        style={{ color: isOverdue ? '#D6331F' : 'initial' }}
      >
        {task.endTime ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            {isOverdue && <WarningIcon style={{ marginRight: '5px' }} />}
            {moment(new Date(task.endTime)).format('DD.MM.YY')}
          </Box>
        ) : (
          <> - </>
        )}
      </TableCell>
      <TableCell width="25%" align="center" className={classes.cell}>
        <div className={classes.avatarCell}>
          {user ? <UserAvatar user={user} className={classes.avatar} /> : <> - </>}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default TaskItem;
